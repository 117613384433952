<template>
    <div class="d-flex flex-column gap-3">
        <span class="d-none bg-red"></span>
        <div class="hstack bg-white w-100 justify-content-between p-3 rounded">
            <div class="vstack">
                <div class="fw-bold fs-5">
                    Estatística
                </div>
                <div class="text-secondary small-text">
                    Filtrar Estatísticas
                </div>
            </div>
            <div class="hstack gap-3">
                <b-dropdown ref="dropdownDate" menu-class="p-0 mt-2" toggle-class="border border-dark-subtle rounded-pill px-3 py-1 text-secondary small-text" variant="white" :text="filters.date == 1 ? 'Hoje' : filters.date == 3 ? 'Este Mês' : filters.date == 5 ? 'Este Ano' : 'Período'">
                    <!-- <b-button class="border-0 border-radius-0 w-100 text-start small-text bg-white text-secondary px-3" @click="pickDate(1)">
                        Hoje
                    </b-button>
                    <b-button class="border-0 border-radius-0 w-100 text-start small-text bg-white text-secondary px-3" @click="pickDate(3)">
                        Este Mês
                    </b-button>
                    <b-button class="border-0 border-radius-0 w-100 text-start small-text bg-white text-secondary px-3" @click="pickDate(4)">
                        Período
                    </b-button> -->
                    <div class="hstack gap-2 p-3 w-max">
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="1">
                            <div class="ms-1 small-text">Hoje</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="3">
                            <div class="ms-1 small-text">Este Mês</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.date" name="radio-date" value="4">
                            <div class="ms-1 small-text">Período</div>
                        </b-form-radio>
                    </div>
                    <div class="vstack gap-3 p-3 pt-0" v-if="filters.date">
                        <div class="vstack gap-3" v-if="filters.date == 4">
                            <b-form-group label-class="text-secondary smaller-text lh-1" label="Data Inicial:">
                                <b-form-input type="date" v-model="filters.startDate"></b-form-input>
                            </b-form-group>
                            <b-form-group label-class="text-secondary smaller-text lh-1" label="Data Final:">
                                <b-form-input type="date" v-model="filters.endDate"></b-form-input>
                            </b-form-group>
                        </div>
                        <b-button class="text-white" variant="green" @click="closeDropdownDate">Fechar</b-button>
                    </div>
                </b-dropdown>
                <b-dropdown ref="dropdownType" menu-class="p-0 mt-2" toggle-class="border border-dark-subtle rounded-pill px-3 py-1 text-secondary small-text" variant="white" :text="filters.type == 1 ? 'Departamento' : filters.type == 2 ? 'Operador' : 'Filtrar por'">
                    <!-- <b-button class="border-0 border-radius-0 w-100 text-start small-text bg-white text-secondary px-3" @click="pickType(1)">
                        Departamento
                    </b-button>
                    <b-button class="border-0 border-radius-0 w-100 text-start small-text bg-white text-secondary px-3" @click="pickType(2)">
                        Operador
                    </b-button> -->
                    <div class="hstack gap-2 p-3 w-max">
                        <b-form-radio class="d-inline-flex" v-model="filters.type" name="radio-type" value="1">
                            <div class="ms-1 small-text">Departamento</div>
                        </b-form-radio>
                        <b-form-radio class="d-inline-flex" v-model="filters.type" name="radio-type" value="2">
                            <div class="ms-1 small-text">Operador</div>
                        </b-form-radio>
                    </div>
                    <div class="p-3 pt-0 vstack gap-3" v-if="filters.type">
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" v-if="filters.type == 1">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{dep.name}}</b-form-select-option>
                        </b-form-select>
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" v-else-if="filters.type == 2">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{op.name}}</b-form-select-option>
                        </b-form-select>
                        <b-button class="text-white" variant="green" @click="closeDropdownType">Fechar</b-button>
                    </div>
                </b-dropdown>
                <b-button class="rounded-pill py-0 px-4 text-white fw-semibold" variant="green" @click="getData()">
                    Gerar
                </b-button>
            </div>
        </div>
        <div class="vstack gap-3" v-if="hasSearched">
            <b-row class="m-0 gap-3">
                <b-col class="bg-white rounded d-flex align-items-center justify-content-center" v-if="loadingFinishedAttendancesData">
                    <b-spinner></b-spinner>
                </b-col>
                <b-col class="bg-white rounded p-3 d-flex flex-column gap-1" v-else>
                    <div class="hstack gap-4 align-items-start">
                        <div>
                            <ChartDoughnut :data="finishedAttendancesData && [ finishedAttendancesData?.finished || 0, finishedAttendancesData?.abandoned || 0, finishedAttendancesData?.opened || 0 ]" :labels="['Finalizados', 'Abandonados', 'Auto Atendimento']" :backgroundClassArray="['bg-red', 'bg-green', 'bg-primary']" style="height: 17vw; width: 17vw;"/>
                        </div>
                        <div>
                            <div class="hstack gap-3">
                                <div class="vr bg-pink opacity-100 rounded" style="width: 3px;"></div>
                                <div class="py-3">
                                    <div class="fs-4 fw-bold text-secondary lh-1">
                                        Atendimentos
                                    </div>
                                    <div class="small-text text-secondary">
                                        Finalizados
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="vstack gap-3 w-100">
                        <div class="hstack gap-2 align-items-end">
                            <div class="vstack gap-1">
                                <div class="d-flex justify-content-between">
                                    <span class="fw-semibold">
                                        <!-- {{ (finishedAttendancesData?.total || 0) | pad(2) }} -->
                                        {{ ((finishedAttendancesData?.total / finishedAttendancesData?.total) || 0) * 100 }}% - {{ finishedAttendancesData?.total || 0 }} Atendimentos
                                    </span>
                                    <span class="small-text text-body-secondary">
                                        Total de atendimentos
                                    </span>
                                </div>
                                <b-progress variant="pink" height=".5em" :value="finishedAttendancesData?.total || 0" :max="finishedAttendancesData?.total || 0"></b-progress>
                            </div>
                            <span v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Visualizar" v-b-modal.viewAttendance @click="getFinishedAttendances">
                                <b-iconstack class="darkenTextOnHover" font-scale="1.75">
                                    <b-icon stacked icon="circle-fill" variant="pink"></b-icon>
                                    <b-icon stacked icon="eye" scale="0.7" variant="white"></b-icon>
                                </b-iconstack>
                            </span>
                        </div>
                        <div class="hstack gap-2 align-items-end">
                            <div class="vstack gap-1">
                                <div class="d-flex justify-content-between">
                                    <span class="fw-semibold">
                                        <!-- {{ (finishedAttendancesData?.finished || 0) | pad(2) }} -->
                                        {{ formatPercentage(finishedAttendancesData?.finished, finishedAttendancesData?.total) }}% - {{ finishedAttendancesData?.finished || 0 }} Atendimentos
                                    </span>
                                    <span class="small-text text-body-secondary">
                                        Atendimentos Finalizados
                                    </span>
                                </div>
                                <b-progress variant="red" height=".5em" :value="finishedAttendancesData?.finished || 0" :max="finishedAttendancesData?.total || 0"></b-progress>
                            </div>
                            <span v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Visualizar" v-b-modal.viewAttendance @click="getFinishedAttendances(1)">
                                <b-iconstack class="darkenTextOnHover" font-scale="1.75">
                                    <b-icon stacked icon="circle-fill" variant="red"></b-icon>
                                    <b-icon stacked icon="eye" scale="0.7" variant="white"></b-icon>
                                </b-iconstack>
                            </span>
                        </div>
                        <div class="hstack gap-2 align-items-end">
                            <div class="vstack gap-1">
                                <div class="d-flex justify-content-between">
                                    <span class="fw-semibold">
                                        <!-- {{ (finishedAttendancesData?.abandoned || 0) | pad(2) }} -->
                                        {{ formatPercentage(finishedAttendancesData?.abandoned, finishedAttendancesData?.total) }}% - {{ finishedAttendancesData?.abandoned || 0 }} Atendimentos
                                    </span>
                                    <span class="small-text text-body-secondary">
                                        Atendimentos Abandonados
                                    </span>
                                </div>
                                <b-progress variant="green" height=".5em" :value="finishedAttendancesData?.abandoned || 0" :max="finishedAttendancesData?.total || 0"></b-progress>
                            </div>
                            <span v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Visualizar" v-b-modal.viewAttendance @click="getFinishedAttendances(2)">
                                <b-iconstack class="darkenTextOnHover" font-scale="1.75">
                                    <b-icon stacked icon="circle-fill" variant="green"></b-icon>
                                    <b-icon stacked icon="eye" scale="0.7" variant="white"></b-icon>
                                </b-iconstack>
                            </span>
                        </div>
                        <div class="hstack gap-2 align-items-end">
                            <div class="vstack gap-1">
                                <div class="d-flex justify-content-between">
                                    <span class="fw-semibold">
                                        <!-- {{ (finishedAttendancesData?.opened || 0) | pad(2) }} -->
                                        {{ formatPercentage(finishedAttendancesData?.opened, finishedAttendancesData?.total) }}% - {{ finishedAttendancesData?.opened || 0 }} Atendimentos
                                    </span>
                                    <span class="small-text text-body-secondary">
                                        Auto Atendimento
                                    </span>
                                </div>
                                <b-progress variant="primary" height=".5em" :value="finishedAttendancesData?.opened || 0" :max="finishedAttendancesData?.total || 0"></b-progress>
                            </div>
                            <span v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Visualizar" v-b-modal.viewAttendance @click="getFinishedAttendances(3)">
                                <b-iconstack class="darkenTextOnHover" font-scale="1.75">
                                    <b-icon stacked icon="circle-fill" variant="primary"></b-icon>
                                    <b-icon stacked icon="eye" scale="0.7" variant="white"></b-icon>
                                </b-iconstack>
                            </span>
                        </div>
                    </div>
                </b-col>
                <b-col class="p-0">
                    <div class="vstack gap-3">
                        <div class="bg-white rounded d-flex justify-content-center p-5" v-if="loadingNewContactsData">
                            <b-spinner></b-spinner>
                        </div>
                        <b-row class="m-0 bg-white rounded p-3 d-flex align-items-center gap-2" v-else>
                            <b-col class="p-0">
                                <div class="hstack gap-3">
                                    <b-avatar class="bg-pink text-white p-1" icon="people" size="5em"></b-avatar>
                                    <div>
                                        <div class="fs-4 fw-bold text-secondary lh-1">
                                            {{ newContactsData?.newContacts || 0 | pad(2) }}
                                        </div>
                                        <div class="smaller-text text-secondary">
                                            Novos Contatos
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                            <b-col class="p-0" cols="5">
                                <div class="hstack gap-3">
                                    <div class="vr bg-pink opacity-100 rounded" style="width: 3px;"></div>
                                    <div class="vstack gap-2">
                                        <div>
                                            <div class="hstack gap-2">
                                                <span class="fw-bold text-secondary">
                                                    {{ newContactsData?.receptiveContacts || 0 | pad(2) }}
                                                </span>
                                                <div class="vr rounded align-self-center"></div>
                                                <span class="small-text text-secondary">
                                                    Contatos Receptivos
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="hstack gap-2">
                                                <span class="fw-bold text-secondary">
                                                    {{ newContactsData?.activeContacts || 0 | pad(2) }}
                                                </span>
                                                <div class="vr rounded align-self-center"></div>
                                                <span class="small-text text-secondary">
                                                    Contatos Ativos
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="hstack gap-2">
                                                <span class="fw-bold text-secondary">
                                                    {{ newContactsData?.totalContacts || 0 | pad(2) }}
                                                </span>
                                                <div class="vr rounded align-self-center"></div>
                                                <span class="small-text text-secondary">
                                                    Contatos Totais
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <div class="bg-white rounded" v-if="user.channelConfig?.enableSatisfactionSurvey">
                            <div class="text-center p-4" v-if="loadingSurveyRatingsData">
                                <b-spinner></b-spinner>
                            </div>
                            <b-row class="m-0 p-3 gap-2" v-else>
                                <b-col class="p-0">
                                    <div class="hstack gap-3">
                                        <div>
                                            <b-avatar class="d-flex bg-warning text-white fw-semibold" :text="surveyRatingsData?.average?.toFixed(1).replace('.',',') || '0'" size="4.75em"></b-avatar>
                                            <b-form-rating class="rating p-0 small-text w-fit" style="width: fit-content !important;" variant="warning" size="sm" :value="surveyRatingsData?.average" inline no-border readonly></b-form-rating>
                                        </div>
                                        <div>
                                            <div class="fs-4 fw-bold text-secondary lh-1">
                                                {{ (surveyRatingsData?.total || 0) | pad(2) }}
                                            </div>
                                            <div class="smaller-text text-secondary">
                                                Quantidade de Avaliações
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col class="p-0" cols="5">
                                    <div class="hstack gap-3 h-100">
                                        <div class="vr bg-warning opacity-100 rounded h-100" style="width: 3px;"></div>
                                        <div class="">
                                            <div class="fw-bold text-secondary fs-4 lh-1">
                                                {{ (surveyRatingsData?.totalChannel || 0) | pad(2) }}
                                            </div>
                                            <div class="small-text text-secondary">
                                                Total de Avaliações
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="bg-white rounded">
                            <div class="text-center p-3" v-if="loadingTopOperatorData">
                                <b-spinner></b-spinner>
                            </div>
                            <b-row class="m-0 p-3 gap-2" v-else>
                                <b-col class="p-0">
                                    <div class="hstack gap-3">
                                        <div>
                                            <b-avatar class="d-flex text-bg-secondary" size="4.75em"></b-avatar>
                                            <b-form-rating class="rating p-0 small-text w-fit" style="width: fit-content !important;" variant="warning" size="sm" :value="topOperatorData?.ratings?.average || 0" inline no-border readonly></b-form-rating>
                                        </div>
                                        <div>
                                            <div class="fs-4 fw-bold text-secondary lh-1 text-truncate" style="max-width: 8em;">
                                                {{ topOperatorData?.name || "- - - -" }}
                                            </div>
                                            <div class="smaller-text text-secondary">
                                                Top Operador {{ user?.name.split(" ")?.at(0) }}
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col class="p-0" cols="5">
                                    <div class="hstack gap-3 h-100">
                                        <div class="vr bg-warning opacity-100 rounded h-100" style="width: 3px;"></div>
                                        <div class="">
                                            <div class="fw-bold text-secondary fs-4 lh-1">
                                                {{ topOperatorData?.ratings?.total || 0 | pad(2) }}
                                            </div>
                                            <div class="small-text text-secondary">
                                                Total de Avaliações
                                            </div>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div>
                <div class="bg-white rounded-top p-3 border-bottom">
                    <div class="fw-bold text-secondary fs-5">
                        Relatório de TME e TMA
                    </div>
                </div>
                <div>
                    <div class="bg-white rounded-bottom d-flex justify-content-center p-5" v-if="loadingAverageTimeData">
                        <b-spinner></b-spinner>
                    </div>
                    <div class="vstack gap-3 p-3 bg-white" v-else-if="averageTimeData?.labels?.length">
                        <div class="hstack gap-5">
                            <div class="hstack gap-2">
                                <div class="bg-warning rounded-circle" style="height: 2em; width: 2em;"></div>
                                <div>
                                    <div class="text-secondary fw-semibold lh-1">
                                        Total Médio
                                    </div>
                                    <div class="smaller-text text-secondary">
                                        Em espera
                                    </div>
                                </div>
                            </div>
                            <div class="hstack gap-2">
                                <div class="bg-red rounded-circle" style="height: 2em; width: 2em;"></div>
                                <div>
                                    <div class="text-secondary fw-semibold lh-1">
                                        Total Médio
                                    </div>
                                    <div class="smaller-text text-secondary">
                                        Em andamento
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ChartLine :height="150" :data="averageTimeData" :borderColor="'#000'" :pointRadius="4" :hitRadius="5" :xAxes="lineChart.xAxes" :yAxes="lineChart.yAxes" :legendTime="lineChart.legendTime" />
                        <div class="hstack gap-5">
                            <div class="vstack gap-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <span class="fw-bold" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Formato - DD:HH:MM:SS">{{ avgTimeWaiting }}</span>
                                    </span>
                                    <span class="text-body-secondary">
                                        Tempo Médio de Espera
                                    </span>
                                </div>
                                <b-progress variant="warning" height=".5em" :value="rawAvgTimeWaiting" :max="rawAvgTimeAttendance + rawAvgTimeWaiting" v-if="avgTimeAttendance && avgTimeWaiting"></b-progress>
                            </div>
                            <div class="vstack gap-1">
                                <div class="d-flex justify-content-between">
                                    <span>
                                        <span class="fw-bold" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Formato - DD:HH:MM:SS">{{ avgTimeAttendance }}</span>
                                    </span>
                                    <span class="text-body-secondary">
                                        Tempo Médio de Atendimento
                                    </span>
                                </div>
                                <b-progress variant="red" height=".5em" :value="rawAvgTimeAttendance" :max="rawAvgTimeAttendance + rawAvgTimeWaiting" v-if="avgTimeAttendance && avgTimeWaiting"></b-progress>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white rounded-bottom text-center text-secondary p-3" v-else>
                        Nenhum dado encontrado!
                    </div>
                </div>
            </div>
            <b-row class="m-0 d-flex gap-3">
                <b-col class="p-0 bg-white rounded" v-if="tabulations?.length">
                    <div class="hstack p-3">
                        <div class="fw-bold text-secondary fs-5">
                            Tabulações
                        </div>
                    </div>
                    <div>
                        <div class="d-flex justify-content-center p-5" v-if="loadingTabulationsData">
                            <b-spinner></b-spinner>
                        </div>
                        <div class="p-3" v-else-if="tabulationsData.values && tabulationsData.values.length">
                            <ChartBar2 
                                :data="tabulationsData.values" 
                                :labels="tabulationsData.labels" 
                                :backgroundColorArray="tabulationsData.colors" 
                                :xAxesHide="true" 
                                :yAxesColor="'#aaa'"
                                :displayLegend="true"
                                style="height: 300px"
                            />
                        </div>
                    </div>
                </b-col>
                <b-col class="p-0 hstack gap-3">
                    <div class="bg-white rounded w-100 p-5 text-center" v-if="loadingTrafficData">
                        <b-spinner></b-spinner>
                    </div>
                    <div class="vstack justify-content-between p-3 gap-3 bg-white rounded" v-else>
                        <div class="hstack gap-2">
                            <b-avatar class="bg-pink text-white" icon="recycle" size="4em"></b-avatar>
                            <div>
                                <div class="fw-bold text-secondary fs-5 lh-1">
                                    Tráfego
                                </div>
                                <div class="small-text text-secondary">
                                    Canal
                                </div>
                            </div>
                        </div>
                        <div class="vstack flex-grow-0 gap-3 w-100">
                            <div class="vstack gap-1">
                                <div class="d-flex justify-content-between">
                                    <span class="fw-semibold">
                                        {{ trafficData?.sent || 0 | pad(2) }}
                                    </span>
                                    <span class="small-text text-body-secondary">
                                        Mensagens Enviadas
                                    </span>
                                </div>
                                <b-progress variant="warning" height=".5em" :value="trafficData?.sent" :max="trafficData?.sent + trafficData?.received"></b-progress>
                            </div>
                            <div class="vstack gap-1">
                                <div class="d-flex justify-content-between">
                                    <span class="fw-semibold">
                                        {{ trafficData?.received || 0 | pad(2) }}
                                    </span>
                                    <span class="small-text text-body-secondary">
                                        Mensagens Recebidas
                                    </span>
                                </div>
                                <b-progress variant="green" height=".5em" :value="trafficData?.received" :max="trafficData?.sent + trafficData?.received"></b-progress>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="vstack p-3 gap-3 bg-white rounded">
                        <div class="hstack gap-2">
                            <b-avatar class="bg-warning text-white" icon="list" size="4em"></b-avatar>
                            <div>
                                <div class="fw-bold text-secondary fs-5 lh-1">
                                    Menus Mais
                                </div>
                                <div class="small-text text-secondary">
                                    Acessados
                                </div>
                            </div>
                        </div>
                        <div class="vstack gap-3 w-100">
                            <div class="vstack gap-1" v-for="menu in topMenus" :key="menu._id">
                                <div class="d-flex justify-content-between">
                                    <span class="fw-semibold">
                                        {{ menu.quantity | pad(2) }}
                                    </span>
                                    <span class="small-text text-body-secondary">
                                        {{ menu.option }}
                                    </span>
                                </div>
                                <b-progress height=".5em" :max="topMenus.reduce((aux, el) => aux + el.quantity, 0)" class="mb-3">
                                    <b-progress-bar :value="menu.quantity" :style="`background-color: ${menu.color};`"></b-progress-bar>
                                </b-progress>
                            </div>
                        </div>
                    </div> -->
                </b-col>
            </b-row>
            <div class="vstack bg-white rounded">
                <div class="p-3">
                    <div class="fw-bold text-secondary fs-5">
                        Top Operadores
                    </div>
                </div>
                <div class="text-center p-5" v-if="loadingTopOperatorsData">
                    <b-spinner></b-spinner>
                </div>
                <div v-else-if="topOperatorsData?.total">
                    <div v-for="operator in topOperatorsData.operators" :key="operator._id">
                        <div class="hstack gap-2 justify-content-between p-3 border-top">
                            <div class="hstack gap-2">
                                <b-avatar class="text-bg-secondary"></b-avatar>
                                <div>
                                    <div class="fw-semibold text-secondary">
                                        {{ operator.name }}
                                    </div>
                                </div>
                            </div>
                            <div class="hstack gap-2">
                                <b-avatar class="d-flex bg-warning text-white fw-semibold" :text="operator.ratings.average?.toFixed(1).replace('.',',') || '0'" size="3em"></b-avatar>
                                <b-form-rating class="rating p-0 small-text w-fit" style="width: fit-content !important;" variant="warning" size="sm" :value="operator.ratings.average || 0" inline no-border readonly></b-form-rating>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center px-3 py-3" v-if="topOperatorsData.total > 5">
                        <b-pagination class="m-0"
                            v-model="topOperatorsCurrentPage"
                            :total-rows="topOperatorsData.total"
                            per-page="5"
                            @change="topOperatorsPageChange"
                        ></b-pagination>
                        <span class="pagdescription">
                            <div class="text-secondary">{{ ( topOperatorsCurrentPage > 1 ) ? ((topOperatorsCurrentPage) * 5) - 5 + 1 : topOperatorsCurrentPage }} - {{(topOperatorsCurrentPage * 5 > topOperatorsData.total) ? topOperatorsData.total : topOperatorsCurrentPage * 5}} de {{ topOperatorsData.total }} Top Operadores</div>
                        </span>
                    </div>
                </div>
                <div class="text-center text-secondary p-3 border-top" v-else>
                    Nenhum dado encontrado!
                </div>
            </div>
        </div>
        <b-modal id="viewAttendance" ref="viewAttendance" :title="finishedAttendancesStatusTitle" size="lg" header-class="py-1" body-class="p-0" hide-footer>
            <div class="text-center m-4" v-if="loadingFinishedAttendances">
                <b-spinner></b-spinner>
            </div>
            <span v-else>
                <div class="overflow-y-auto" style="max-height: 75vh;">
                    <b-row class="border-bottom p-3 m-0 gap-4" v-for="att in finishedAttendances" :key="att._id">
                        <b-col class="p-0 align-self-center">
                            <span class="hstack gap-3">
                                <b-avatar :src="att.photoURL" class="bg-secondary text-white"></b-avatar>
                                <div class="fw-semibold text-secondary box-clamp-1">
                                    {{ att.clientName }}
                                </div>
                            </span>
                        </b-col>
                        <b-col class="p-0 align-self-center w-fit" cols="3">
                            <span class="vstack gap-1">
                                <div class="fw-semibold text-secondary">
                                    Início do atendimento
                                </div>
                                <div class="text-secondary small-text text-capitalize">
                                    {{ att.attendanceStartTime | date('MMM DD [●] HH:mm:ss') }}
                                </div>
                            </span>
                        </b-col>
                        <b-col class="p-0 align-self-center w-fit" cols="3">
                            <span class="vstack gap-1">
                                <div class="fw-semibold text-secondary">
                                    Término do atendimento
                                </div>
                                <div class="text-secondary small-text text-capitalize">
                                    {{ att.attendanceEndTime | date('MMM DD [●] HH:mm:ss') }}
                                </div>
                            </span>
                        </b-col>
                        <b-col class="p-0 align-self-center d-flex justify-content-end w-fit" cols="2">
                            <span class="hstack gap-2">
                                <b-button variant="pink" class="text-white p-1" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Encaminhar Atendimento" @click="selectClient(att); getForwardList()" v-b-modal.modalForward>
                                    <b-icon icon="reply-fill" flip-h></b-icon>
                                </b-button>
                                <b-button variant="green" class="text-white p-1" v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Visualizar Atendimento" @click="loadMessages(att._id, att.operatorId)" v-b-modal.contactMessages>
                                    <b-icon icon="eye"></b-icon>
                                </b-button>
                            </span>
                        </b-col>
                    </b-row>
                </div>
                <div class="d-flex justify-content-between align-items-center px-3 py-3">
                    <b-pagination class="m-0"
                        v-model="finishedAttendancesCurrentPage"
                        :total-rows="finishedAttendancesTotal"
                        :per-page="finishedAttendancesPerPage"
                        @change="finishedAttendancesPageChange"
                        :disabled="finishedAttendancesTotal <= finishedAttendancesPerPage"
                    ></b-pagination>
                    <span class="pagdescription">
                        <div class="text-secondary">{{ ( finishedAttendancesCurrentPage > 1 ) ? ((finishedAttendancesCurrentPage) * finishedAttendancesPerPage) - finishedAttendancesPerPage + 1 : finishedAttendancesCurrentPage }} - {{(finishedAttendancesCurrentPage * finishedAttendancesPerPage > finishedAttendancesTotal) ? finishedAttendancesTotal : finishedAttendancesCurrentPage * finishedAttendancesPerPage}} de {{ finishedAttendancesTotal }} Atendimentos</div>
                    </span>
                </div>
            </span>
        </b-modal>
        <b-modal id="contactMessages" ref="contactMessages" title="Visualizar Atendimento" header-class="py-0" hide-footer>
            <div class="d-flex align-items-center mb-2" v-if="clientSelected">
                <b-avatar class="bg-secondary text-white" size="3rem" :src="clientSelected.photoURL"></b-avatar>
                <div class="text-purple fw-bold ms-2">
                    {{ clientSelected.clientName }} 
                </div>
            </div>
            <div>
                <Whatsapp :messages="contactMessages" :height="'72vh'" :mediaURL="mediaURL" />
            </div>
        </b-modal>
        <b-modal id="modalForward" ref="modalForward" hide-footer title="Encaminhar Atendimento" header-class="py-0" v-if="clientSelected">
            <b-form-group label="Encaminhar Para:" label-class="text-purple fw-semibold">
                <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="forward.operator">
                    <b-form-select-option :value="op" v-for="op in onlineOperators" :key="op._id">{{ op.name }}</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <div class="mt-3">
                <b-button class="text-white" variant="green" @click="startAttendance()">Encaminhar</b-button>
                <b-button class="ms-2" variant="danger" @click="$bvModal.hide('modalForward')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modalContactInSurvey" ref="modalContactInSurvey" title="Contato em Pesquisa de Satisfação" body-class="p-0" header-class="py-1" hide-footer>
            <div class="vstack gap-3 p-3">
                <div class="vstack text-secondary">
                    <div class="fw-bold">
                        Este contato está em pesquisa de satisfação.
                    </div>
                    <div>
                        Deseja continuar e cancelar a pesquisa?
                    </div>
                </div>
                <div class="hstack gap-3">
                    <b-button variant="success" @click="startAttendance(true)" :disabled="startingAttendance">Continuar</b-button>
                    <b-button variant="danger" @click="$bvModal.hide('modalContactInSurvey')">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <FAB :screenshotBtn="true" />
    </div>
</template>

<script>
import api from '../services/apiService.js'
import ChartDoughnut  from './chartDoughnut.vue'
import ChartLine from './chartLine.vue'
import ChartBar2 from './chartBar2.vue'
import utils from '../utils/utils.js'
import Whatsapp from './whatsapp.vue'
import FAB from './FAB.vue'

export default {
    props: [
        "user", 'timestampDiff', 'mediaURL'
    ],
    components: {
        ChartDoughnut, ChartLine, ChartBar2, Whatsapp, FAB
    },
    mounted: function() {
        this.onCloseModal()
        this.getDepartments()
        this.getOperators()
        this.getTabulations()
    },
    data() {
        return {
            filters: {
                date: '3',
                startDate: null,
                endDate: null,
                type: null,
                typeId: null
            },
            loadingAverageTimeData: false,
            loadingNewContactsData: false,
            loadingTabulationsData: false,
            loadingFinishedAttendancesData: false,
            loadingFinishedAttendances: false,
            loadingSurveyRatingsData: false,
            loadingTrafficData: false,
            loadingTopOperatorData: false,
            loadingTopOperatorsData: false,
            hasSearched: false,
            averageTimeData: {},
            avgTimeAttendance: "DD:HH:MM:SS",
            avgTimeWaiting: "DD:HH:MM:SS",
            rawAvgTimeAttendance: 0,
            rawAvgTimeWaiting: 0,
            operators: [],
            onlineOperators: [],
            departments: [],
            topMenus: [],
            newContactsData: null,
            tabulationsData: {},
            tabulations: [],
            finishedAttendancesData: null,
            finishedAttendancesStatus: 0,
            finishedAttendances: null,
            finishedAttendancesCurrentPage: 1,
            finishedAttendancesPerPage: 10,
            finishedAttendancesTotal: 0,
            surveyRatingsData: null,
            trafficData: null,
            topOperatorData: null,
            topOperatorsData: [],
            topOperatorsCurrentPage: 1,
            contactMessages: [],
            clientSelected: null,
            forward: {},
            lineChart: {
                yAxes: {},
                xAxes: {
                    display: true,
                    ticks: {
                        display: true
                    }
                },
                legendTime: true
            },
            startingAttendance: false
        }
    },
    computed: {
        finishedAttendancesStatusTitle: function() {
            switch(this.finishedAttendancesStatus) {
                case 1:
                    return "Atendimentos Finalizados"
                case 2:
                    return "Atendimentos Abandonados"
                case 3:
                    return "Auto Atendimento"
                default:
                    return "Total de Atendimentos"
            }
        }
    },
    methods: {
        pickDate(date) {
            this.filters.date = date
            if(date != 4) {
                this.closeDropdownDate()
            }
        },
        pickType(type) {
            this.filters.type = type
        },
        closeDropdownDate() {
            this.$refs.dropdownDate.hide(true)
        },
        closeDropdownType() {
            this.$refs.dropdownType.hide(true)
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.operators = []
                this.operators['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators
        },
        async getOnlineOperators() {
            const resp = await api.getOnlineOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.onlineOperators = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.onlineOperators = resp.operators
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        async getData(topOperatorsPage = 1) {
            if(this.filters.date == 4) {
                if(!this.filters.startDate) {
                    return this.$emit("msg", {
                        text: 'Campo "Data Inicial" vazio!',
                        success: false
                    })
                } else if(!this.filters.endDate) {
                    return this.$emit("msg", {
                        text: 'Campo "Data Inicial" vazio!',
                        success: false
                    })
                }

                this.filters.firstPeriod = this.filters.startDate 
                this.filters.finalPeriod = this.filters.endDate 
            }
            
            if(this.filters.type && this.filters.typeId) {
                switch(this.filters.type) {
                    case "1":
                    case 1:
                        this.filters.department = this.filters.typeId
                        break
                    case "2":
                    case 2:
                        this.filters.operator = this.filters.typeId
                        break
                }
            } else if(!this.filters.typeId) {
                this.filters.type = null
            }

            this.getSurveyRatingsData()
            this.getTopOperatorData()
            this.getFinishedAttendancesCount()
            this.getNewContactsData()
            this.getAverageTimeData()
            this.getTabulationsData()
            this.getTrafficData()
            this.getTopMenusData()
            this.getTopOperatorsData(topOperatorsPage)
            this.hasSearched = true
        },
        async getFinishedAttendancesCount() {
            this.loadingFinishedAttendancesData = true

            const attendances = {
                finished: 0,
                abandoned: 0,
                opened: 0,
                total: 0
            }

            const resp = await api.getFinishedAttendancesCount({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate })
            // console.log('resp getFinishedAttendancesCount', resp)

            if(resp.statusCode == 200) {
                this.finishedAttendancesData = resp.count
            } else {
                this.finishedAttendancesData = attendances
            }

            this.loadingFinishedAttendancesData = false
        },
        async getFinishedAttendances(status = 0, page = 1) {
            this.loadingFinishedAttendances = true

            this.filters.status = status
            const resp = await api.getFinishedAttendancesPagination({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate }, page)

            // console.log({resp})

            if(this.finishedAttendancesStatus !== status)
                this.finishedAttendancesStatus = status

            if(resp.statusCode == 200) {
                this.finishedAttendances = resp.attendances
                this.finishedAttendancesTotal = resp.total
            }

            this.loadingFinishedAttendances = false
        },
        async getNewContactsData() {
            this.loadingNewContactsData = true
            const resp = await api.getReportNewContacts(this.user.channelId || this.user.roleId, this.filters, this.filters.startDate, this.filters.endDate)
            // console.log("resp",resp)
            if(resp.statusCode == 200) {
                this.newContactsData = resp.newContactsData
            } else {
                this.newContactsData = null
            }
            this.loadingNewContactsData = false
        },
        async getSurveyRatingsData() {
            this.loadingSurveyRatingsData = true

            const resp = await api.getReportSurveyRatingsData(this.filters)
            console.log("resp",resp)
            if(resp.statusCode == 200) {
                this.surveyRatingsData = resp.ratings
            } else {
                this.surveyRatingsData = {}
            }

            this.loadingSurveyRatingsData = false
        },
        async getTopOperatorData() {
            this.loadingTopOperatorData = true

            const resp = await api.getSurveyTopOperator(this.filters)
            // console.log("resp topOperator",resp)
            if(resp.statusCode == 200) {
                this.topOperatorData = resp.topOperator
            } else {
                this.topOperatorData = null
            }

            this.loadingTopOperatorData = false
        },
        async getTopOperatorsData(page = 1) {
            this.loadingTopOperatorsData = true

            const resp = await api.getSurveyTopOperators(this.filters, page)
            // console.log("resp topOperators",resp)
            if(resp.statusCode == 200) {
                this.topOperatorsData = resp
            } else {
                this.topOperatorsData = null
            }

            this.loadingTopOperatorsData = false
        },
        async getAverageTimeData() {
            this.loadingAverageTimeData = true
            const resp = await this.getReportAverageCount({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate })
            // console.log('resp count',resp)
            if(resp) {
                const labels = []
                const values = [
                    {
                        label: "Tempo Médio em Espera",
                        backgroundColor: utils.applyTransparency(utils.getClassColor("bg-warning"), 0.9),
                        data: []
                    },
                    {
                        label: "Tempo Médio em Atendimento",
                        backgroundColor: utils.applyTransparency(utils.getClassColor("bg-red"), 0.9),
                        data: []
                    },
                ]

                this.$set(this.averageTimeData, 'labels', labels)
                this.$set(this.averageTimeData, 'values', values)

                this.avgTimeWaiting = this.formatTime(resp.avgTimeWaiting)
                this.avgTimeAttendance = this.formatTime(resp.avgTimeAttendance)
                this.rawAvgTimeWaiting = resp.avgTimeWaiting
                this.rawAvgTimeAttendance = resp.avgTimeAttendance

                const promise = resp.count.map(async el => {
                    if(el) {
                        values[0].data.push(el.avgTimeWaiting || 0) // TME
                        values[1].data.push(el.avgTimeAttendance || 0) // TMA
                    } else
                        values.map(value => value.data.push(0))
                    
                    if(el.hour)
                        labels.push(el.hour)
                    else if(el.month)
                        labels.push(el.month)
                    else
                        labels.push(this.$options.filters.date(el.date,'DD/MM/YY',true))
                    // values.push(el.count)
                    return el
                })

                await Promise.all(promise)

                // values = values.filter(el => {
                //     return !el.data.every(el => el == 0 || el == undefined)
                // })

                this.$set(this.averageTimeData, 'labels', labels)
                this.$set(this.averageTimeData, 'values', values)
            } else {
                this.$set(this.averageTimeData, 'labels', [])
                this.$set(this.averageTimeData, 'values', [])
            }
            this.loadingAverageTimeData = false
        },
        async getTabulationsData() {
            this.loadingTabulationsData = true

            this.tabulationsData = {}
            const resp = await this.getReportTabulationCount({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate })
            // console.log('resp tabulationsData',resp)
            if(resp) {
                const labels = [] , values = [], colors = []
                const aux = {}

                const promise = resp.map(async el => {
                    if(el.completionReason?.length) {
                        let promise2 = el.completionReason.map(reason => {
                            if(reason && reason != "Atendimento finalizado pelo tempo de espera." && reason != 'finished' && reason != 'in_survey') {
                                if(aux[reason]) {
                                    aux[reason] += 1
                                }
                                else
                                    aux[reason] = 1
                            }
                        })
                        await Promise.all(promise2)
                    }
                })
                await Promise.all(promise)
                
                const promise2 = this.tabulations.map(el => {
                    if(!aux[el.text])
                        aux[el.text] = 0
                })
                await Promise.all(promise2)

                for (const key in aux) {
                    if(Object.hasOwn(aux, key)) {
                        labels.push(key)
                        values.push(aux[key])
                        colors.push(utils.generateColor())
                        // console.log(`${key}: ${aux[key]}`);
                    }
                }

                this.$set(this.tabulationsData, 'labels', labels)
                this.$set(this.tabulationsData, 'values', values)
                this.$set(this.tabulationsData, 'colors', colors)
            }

            this.loadingTabulationsData = false
        },
        async getTrafficData() {
            this.loadingTrafficData = true

            const resp = await api.getMessagesTotalSentReceived(this.user.channelId || this.user.roleId, this.filters)
            // console.log("resp TrafficData",resp)
            if(resp.statusCode == 200) {
                this.trafficData = resp.totalMessages
            } else
                this.trafficData = {}

            this.loadingTrafficData = false
        },
        async getTopMenusData() {
            const resp = await api.getTopMenus(this.user.channelId || this.user.roleId)
            // console.log("topMenus resp",resp)
            if(resp.statusCode == 200 && resp.menus.length) {
                resp.menus = resp.menus.filter(el => Object.hasOwn(el, 'option'))
                const promise = resp.menus.map(el => {
                    el.color = utils.generateColor()
                    return el
                })
                await Promise.all(promise)
                this.topMenus = resp.menus.sort((a,b) => (a.quantity < b.quantity) ? 1 : ((b.quantity < a.quantity) ? -1 : 0))
            }
        },
        async getReportAverageCount(data) {
            const resp = await api.getReportAverageCount(data)
            if(resp.statusCode == 200) {
                return resp.attendances
            }
            return false
        },
        formatTime(ms) { // DD:HH:MM:SS
            if (ms) {
                const pad = function(n){ return n < 10 ? '0' + n : n; };
                const s = Math.floor(ms / 1000);
                const m = Math.floor(s / 60);
                const h = Math.floor(m / 60);
                const d = Math.floor(h / 24);
                return pad(d) + ":" + pad(h % 24) + ":" + pad(m % 60) + ":" + pad(s % 60);
            }
            return "00:00:00:00";
        },
        async getReportTabulationCount(data) {
            const resp = await api.getReportTabulationCount(data)
            if(resp.statusCode == 200) {
                return resp.attendances
            }
            return false
        },
        async getTabulations() {
            const resp = await api.getTabulations(this.user.channelId || this.user.roleId)
            if(resp.statusCode == 200) {
                this.tabulations = resp.tabulations
            } else {
                // let msg = {
                //     text: 'Nenhuma tabulação encontrada!',
                //     success: false
                // }
                // this.$emit('msg',msg)
            }
        },
        async getReportAttendanceCount(data) {
            const resp = await api.getReportAttendanceCount(data)
            if(resp.statusCode == 200) {
                return resp.attendances
            }
            return false
        },
        topOperatorsPageChange(e) {
            if(e != this.topOperatorsCurrentPage) {
                this.topOperatorsCurrentPage = e
                this.getTopOperatorsData(e)
            }
        },
        finishedAttendancesPageChange(e) {
            if(e != this.finishedAttendancesCurrentPage) {
                this.finishedAttendancesCurrentPage = e
                this.getFinishedAttendances(this.finishedAttendancesStatus, e)
            }
        },
        getForwardList() {
            this.getOnlineOperators()
            this.getDepartments()
        },
        async loadMessages(attendanceId, operatorId) {
            const resp = await api.loadMessages({
                attendance: {
                    _id: attendanceId
                },
                operatorId
            })

            if (resp.statusCode === 200) {
                resp.messages.messages.map(el => {
                    if(this.timestampDiff > 100)
                        el.timestamp -= this.timestampDiff
    
                    if(el.timestamp > 9999999999)
                        el.timestamp /= 1000
                })
    
                this.contactMessages = resp.messages.messages
            }
        },
        async startAttendance() {
            if(this.forward.operator) {
                this.startingAttendance = true

                const contact = this.clientSelected
                // in this cases, contact comes from old attendance
                if(!contact.number) contact.number = contact.clientNumber
                if(!contact.name) contact.name = contact.clientName
    
                const resp = await api.retrieveAttendance(this.forward.operator.channelId, { contact, operatorId: this.forward.operator._id })
                
                this.startingAttendance = false
                
                if(this.$refs["modalContactInSurvey"])
                    this.$refs["modalContactInSurvey"].hide()

                let msg
                if(resp.statusCode == 200) {
                    msg = {
                        text: "Atendimento encaminhado com sucesso!",
                        success: true
                    }
                    if(this.$refs['modalForward'])
                        this.$refs['modalForward'].hide()
                } else if(resp.statusCode == 406) {
                    let text

                    switch(resp.attendance.status) {
                        case 'opened':
                            text = "Este contato está com atendimento em andamento no ChatBot"
                            break
                        case 'offline_operators':
                            text = "Este contato está com atendimento em andamento na fila de espera"
                            break
                        default:
                            if (resp.attendance.operatorName)
                                text = `Este contato está com atendimento em andamento com ${resp.attendance.operatorName}!`
                            else
                                text = "Este contato está com atendimento em andamento"
                    }

                    msg = {
                        text,
                        success: false
                    }
                } else if(resp.statusCode == 408) {
                    msg = {
                        text: "O horário de atendimento deste operador foi finalizado!",
                        success: false,
                        countdown: 10
                    }
                } else if(resp.statusCode === 409) {
                    return this.$bvModal.show("modalContactInSurvey")
                } else {
                    msg = {
                        text: "Ocorreu um erro!",
                        success: false
                    }
                }
                if(msg) {
                    this.$emit('msg',msg)
                }
            }
        },
        selectClient(item) {
            this.clientSelected = item
        },
        formatPercentage(value, total) {
            const n = ((value / total) || 0) * 100
            if(Number.isInteger(n)) {
                return n
            }
            return n.toFixed(1)
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                this.clientSelected = null
                this.contactMessages = []

                if(modalId == "viewAttendance")
                    this.finishedAttendancesCurrentPage = 0
            })
        },
    }
}
</script>

<style>
    .rating.b-rating .b-rating-star, .rating.b-rating .b-rating-value {
        padding: .1em !important;
    }
</style>